/*
 * charity.model.ts
 * Little Phil
 *
 * Created on 16/5/20
 * Copyright © 2018 Little Phil. All rights reserved.
 */
import { CAUSE_TYPE, CHARITY_STATUS, STRIPE_ACCOUNT_DISABLED_REASON } from '@little-phil/js/lib/common/enums';
import { ROUTE_PATHS } from '@little-phil/js/lib/common/constants';
import { appendQuery } from '@little-phil/js/lib/utils/append-query';

import { BaseModel } from './_base.model';
import { Category } from './category.model';
import { environment } from '../../environments/environment';
import { User } from './user.model';

/**
 * Interface representing the contact details sub-schema for charities
 */
export interface ICharityContactDetails {
    email?: string;
    phoneNumber?: string;
}

export class Charity extends BaseModel {

    public static endpoint = 'charity';

    // Properties
    public readonly businessName: string = undefined;
    public readonly displayName: string = undefined;
    public readonly businessTaxId: string = undefined;
    public readonly businessUrl: string = undefined;
    public readonly description: string = undefined;
    public readonly imageUrl: string = undefined;
    public readonly heroImageUrl: string = undefined;
    public readonly charityBannerUrl: string = undefined;
    public readonly addressLine1: string = undefined;
    public readonly addressLine2: string = undefined;
    public readonly city: string = undefined;
    public readonly countryCode: string = undefined;
    public readonly state: string = undefined;
    public readonly postalCode: string = undefined;
    public readonly accountNumber: string = undefined;
    public readonly bsb: string = undefined;
    public readonly accountName: string = undefined;
    public readonly bankName: string = undefined;
    public readonly accountType: string = undefined;
    public readonly whitelabelId: string = undefined;
    public readonly establishmentYear: number = undefined;
    public readonly latitude: number = undefined;
    public readonly longitude: number = undefined;
    public readonly displayLocation: string = undefined;
    public readonly lpFeePercentage: number = undefined;
    public status: CHARITY_STATUS = undefined;
    public readonly pendingToApprovedConversionDate: Date = undefined;
    public readonly leadToPendingConversionDate: Date = undefined;
    public readonly applicationToken: string = undefined;
    public readonly applicationS3Directory: string = undefined;
    public readonly stripeId: string = undefined;
    public readonly stripeDisabledReason: STRIPE_ACCOUNT_DISABLED_REASON = undefined;
    public stripeCurrentDeadline: Date = undefined;
    public readonly chargesEnabled: boolean = undefined;
    public readonly payoutsEnabled: boolean = undefined;
    public readonly automaticPayoutsDisabled: boolean = undefined;
    public category: Category | string = undefined;
    public accountManager: User | string = undefined;
    public readonly amountGiven: number = undefined;
    public readonly uniqueGivers: number = undefined;
    public readonly isDGRRegisteredOverridden: boolean = undefined;
    public readonly isDGRRegistered: boolean = undefined;
    public active: boolean = undefined;
    public readonly contactDetails: ICharityContactDetails = undefined;
    public readonly slug: string = undefined;
    public readonly youtubeUrl: string = undefined;

    /**
     * Constructor
     * @param {object} json
     */
    constructor(json: object = {}) {
        super();
        super.init(json);
    }

    protected deserialise() {
        if (typeof this.category === 'object' && !(this.category instanceof Category)) {
            this.category = new Category(this.category);
        }

        if (typeof this.accountManager === 'object' && !(this.accountManager instanceof User)) {
            this.accountManager = new User(this.accountManager);
        }

        if (typeof this.stripeCurrentDeadline === 'string') {
            this.stripeCurrentDeadline = new Date(this.stripeCurrentDeadline);

            if (isNaN(this.stripeCurrentDeadline.getTime())) {
                this.stripeCurrentDeadline = null;
                console.error('BaseModel: Failed to parse date.'); // logger is not accessible here because this isn't an Angular class
            }
        }
    }

    /**
     * Checks if the charity's Stripe disabled reason is due to pending verification
     */
    public get isStripePending() {
        return this.stripeDisabledReason === STRIPE_ACCOUNT_DISABLED_REASON.REQUIREMENTS_PENDING_VERIFICATION;
    }

    /**
     * Checks if the charity's Stripe disabled reason is due to past due requirements
     */
    public get isStripePastDue() {
        return this.stripeDisabledReason === STRIPE_ACCOUNT_DISABLED_REASON.REQUIREMENTS_PAST_DUE;
    }

    /**
     * Returns the name to display for the charity
     */
    public getDisplayName() {
        return this.displayName || this.businessName;
    }

    /**
     * Returns the URL for the charity
     */
    public getUrl() {
        return ROUTE_PATHS.web.charity.details(this.slug || this.id);
    }

    /**
     * Returns the cause type
     */
    public getType() {
        return CAUSE_TYPE.CHARITY;
    }

    /**
     * Builds the application link for a charity
     */
    public get applicationLink() {
        if (this.applicationToken) {
            return appendQuery(environment.webBaseUrl + ROUTE_PATHS.web.apply, {
                c: this.id,
                t: this.applicationToken,
            });
        }
        return undefined;
    }

    /**
     * Returns the background colour for the status badge
     */
    public getStatusBadgeBackgroundColour() {
        switch (this.status) {
            case CHARITY_STATUS.APPROVED:
                return '#7DE7B5';
            case CHARITY_STATUS.PENDING:
                return '#F8AD6D';
            case CHARITY_STATUS.LEAD:
                return '#B9ECFD';
        }
    }

    /**
     * Returns the background colour for the status badge
     */
    public getStatusBadgeTextColour() {
        switch (this.status) {
            case CHARITY_STATUS.APPROVED:
                return '#203B4C';
            case CHARITY_STATUS.PENDING:
                return '#203B4C';
            case CHARITY_STATUS.LEAD:
                return '#203B4C';
        }
    }

}
