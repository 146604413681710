/*
 * company.model.ts
 * Little Phil
 *
 * Created on 2/8/21
 * Copyright © 2018 Little Phil. All rights reserved.
 */

import { BaseModel } from './_base.model';
import { User } from './user.model';

/**
 * Interface for employees of a company
 */
export interface IEmployee {
    employee: User | string;
    dateAdded: Date;
}

export class Company extends BaseModel {

    public static endpoint = 'company';

    // Properties
    public readonly displayName: string = undefined;
    public readonly businessName: string = undefined;
    public readonly countryCode: string = undefined;
    public readonly industry: string = undefined;
    public readonly displayLocation: string = undefined;
    public readonly latitude: number = undefined;
    public readonly longitude: number = undefined;
    public readonly imageUrl: string = undefined;
    public readonly heroImageUrl: string = undefined;
    public readonly phoneNumber: string = undefined;
    public readonly lpcAmountPerEmployee: number = undefined;
    public readonly creditDayOfMonth: number = undefined;
    public readonly lpcImpact: number = undefined;
    public readonly numberOfDonations: number = undefined;
    public readonly stripeCompanyGivingCreditSubscriptionId: string = undefined;
    public readonly stripeCompanyGivingCreditSubscriptionNextBillingDate: Date = undefined;
    public employees: IEmployee[] = undefined;

    /**
     * Constructor
     * @param {object} json
     */
    constructor(json: object = {}) {
        super();
        super.init(json);
    }

    /**
     * Returns the name to display for the company
     */
    public getDisplayName() {
        return this.displayName || this.businessName;
    }

    /**
     * Return the number of employees
     */
    public get numberOfEmployees() {
        return this.employees?.length || 0;
    }

    protected deserialise() {
        if (Array.isArray(this.employees)) {
            this.employees = this.employees.map(({ dateAdded, employee }) => {
                if (typeof employee === 'object' && !(employee instanceof User)) {
                    return {
                        dateAdded,
                        employee: new User(employee),
                    };
                } else {
                    return {
                        dateAdded,
                        employee,
                    };
                }
            });
        }
    }
}
