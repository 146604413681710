import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Category } from '../../../_models/category.model';
import { Charity } from '../../../_models/charity.model';
import { z } from 'zod';
import { FormComponent } from '../../../_classes/form-component.class';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import {
    DashboardFormGoogleMapsComponent,
    GoogleMapsLocation,
} from '../../../_components/forms/dashboard-form-google-maps/dashboard-form-google-maps.component';
import { Subscription } from 'rxjs';
import { LoggerService } from '../../../_services/logger.service';
import { Router } from '@angular/router';
import { AuthService } from '../../../_services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { redirectToLogin } from '../../../_utils/router.util';
import { extractErrorMessageFromError } from '../../../_utils/errors.util';
import { IImageChangeEvent } from '../../../_modules/lp-form/components/form-image/form-image.component';
import { ALIGN } from 'src/app/_utils/constants.util';
import { ARROW_RIGHT_SVG } from 'src/app/_utils/icons-brand.util';
import { yesOrNoSelectOptions } from 'src/app/_utils/form.util';
import { DashboardFormSelectComponent } from '../../../_components/forms/dashboard-form-select/dashboard-form-select.component';
import { SvgIconComponent } from '../../../_components/svg-icon/svg-icon.component';
import { DashboardFormInputComponent } from '../../../_components/forms/dashboard-form-input/dashboard-form-input.component';
import {
    CampaignListItemStandaloneComponent,
} from '../../../_components/campaign-list-item-standalone/campaign-list-item-standalone.component';
import { LpFormModule } from '../../../_modules/lp-form/lp-form.module';
import { DashboardFormTextAreaComponent } from '../../../_components/forms/dashboard-form-text-area/dashboard-form-text-area.component';
import { DashboardFormControlComponent } from '../../../_components/forms/dashboard-form-control/dashboard-form-control.component';
import { CharityCardComponent } from '../../../_components/charity-card/charity-card.component';
import { CHARITY_STATUS } from '@little-phil/js/lib/common/enums';

@Component({
    selector: 'app-charity-crud-form',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DashboardFormSelectComponent,
        SvgIconComponent,
        DashboardFormInputComponent,
        CampaignListItemStandaloneComponent,
        LpFormModule,
        DashboardFormTextAreaComponent,
        DashboardFormControlComponent,
        DashboardFormGoogleMapsComponent,
        CharityCardComponent,
    ],
    templateUrl: './charity-crud-form.component.html',
    styles: [
        `
            :host {
                display: block;
            }
        `,
    ],
})
export class CharityCrudFormComponent extends FormComponent implements OnInit, OnDestroy, OnChanges {

    @Input() buttonTitle: string;
    @Input() charity: Charity;
    @Input() categories: Category[];

    @Output() onSubmit = new EventEmitter<{
        data: CharityCrudFormData,
        image: Blob,
        heroImage: Blob
    }>();

    public goal: number;
    public charityName: string;
    public charityLogoUrl: string;

    public form: FormGroup<{
        // Details
        displayName: FormControl<string>,
        description: FormControl<string>,
        category: FormControl<Category>,
        slug: FormControl<string>,
        youtubeUrl: FormControl<string>,

        // Location
        displayLocation: FormControl<string>,
        latitude: FormControl<number>,
        longitude: FormControl<number>,

        // Images
        image: FormControl<string>,
        heroImage: FormControl<string>,

        // Admin
        automaticPayoutsDisabled: FormControl<boolean>,
        isDGRRegistered: FormControl<boolean>,
        isDGRRegisteredOverridden: FormControl<boolean>
    }>;

    public formState: string;
    public imageUrl: string;
    public image: Blob;
    public heroImageUrl: string;
    public heroImage: Blob;
    public isAdmin: boolean;
    public initialLocation: GoogleMapsLocation;

    protected readonly ALIGN = ALIGN;
    protected readonly ARROW_RIGHT_SVG = ARROW_RIGHT_SVG;
    protected readonly CHARITY_STATUS = CHARITY_STATUS;

    public readonly yesOrNoSelectOptions = yesOrNoSelectOptions;
    public readonly subscriptions: Subscription[] = [];

    constructor(
        protected logger: LoggerService,
        private router: Router,
        private authService: AuthService,
        private fb: FormBuilder,
        private toast: ToastrService,
    ) {
        super(logger);
    }

    ngOnInit() {
        const user = this.authService.getUser();

        if (!user) {
            redirectToLogin(this.router, this.router.url);
            return;
        } else if (user.isAdmin) {
            this.isAdmin = true;
        }

        this.createForm();
        this.setupInitialState();
    }

    ngOnDestroy() {
        for (const sub of this.subscriptions) {
            sub.unsubscribe();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.campaign && this.form) {
            const newValue = changes.campaign.currentValue;
            const oldValue = changes.campaign.previousValue;

            // Ensure that transformed slug is replaced after saving a campaign
            if (newValue?.slug !== oldValue?.slug) {
                this.form.controls.slug.setValue(newValue?.slug);
            }
        }
    }

    /**
     * Sets up the forms initial state
     */
    protected createForm() {
        this.form = this.fb.group({
            // Details
            displayName: this.fb.control<string>(
                this.charity?.displayName || null,
                [Validators.required],
            ),
            category: this.fb.control<Category>(
                this.charity?.category instanceof Category
                    ? this.charity.category
                    : null,
                [Validators.required],
            ),
            description: this.fb.control<string>(
                this.charity?.description || null,
                [Validators.required],
            ),
            slug: this.fb.control<string>(this.charity?.slug || null),
            youtubeUrl: this.fb.control<string>(this.charity?.youtubeUrl || null),

            // Location
            displayLocation: this.fb.control<string>(
                this.charity?.displayLocation || null,
                [Validators.required],
            ),
            latitude: this.fb.control<number>(
                this.charity?.latitude || null,
                [Validators.required],
            ),
            longitude: this.fb.control<number>(
                this.charity?.longitude || null,
                [Validators.required],
            ),

            // Images
            image: this.fb.control<string>(null),
            heroImage: this.fb.control<string>(null),

            // Admin
            automaticPayoutsDisabled: this.fb.control<boolean>(this.charity?.automaticPayoutsDisabled || false),
            isDGRRegistered: this.fb.control<boolean>(this.charity?.isDGRRegistered || false),
            isDGRRegisteredOverridden: this.fb.control<boolean>(this.charity?.isDGRRegisteredOverridden || false),
        });
    }

    private setupInitialState() {
        this.imageUrl = this.charity?.imageUrl;
        this.heroImageUrl = this.charity?.heroImageUrl;

        if (
            this.charity &&
            this.charity.city &&
            typeof this.charity.latitude === 'number' &&
            typeof this.charity.longitude === 'number'
        ) {
            this.initialLocation = {
                location: this.charity.city,
                lat: this.charity.latitude,
                lng: this.charity.longitude,
            };
        }
    }

    public handleLocationChange(location: GoogleMapsLocation) {
        this.form.controls.displayLocation.setValue(location.location);
        this.form.controls.latitude.setValue(location.lat);
        this.form.controls.longitude.setValue(location.lng);
    }

    public handleSubmit() {
        if (!this.validateForm()) {
            return;
        }


        const data = {
            ...this.form.value,
            category: this.form.controls.category.value.id,
        };

        try {
            if (this.authService.getUser().isAdmin) {
                // TODO handle create
                // switch (this.mode) {
                //     case 'create':
                //         this.onSubmit.emit({
                //             data: campaignCreateAdminFormSchema.parse({
                //                 ...data,
                //                 charity: this.form.controls.charity.value,
                //                 featured: this.form.controls.featured.value,
                //             }),
                //             image: this.image,
                //         });
                //         break;
                //     case 'edit':
                this.onSubmit.emit({
                    data: charityEditAdminFormSchema.parse(data),
                    image: this.image,
                    heroImage: this.heroImage,
                });
                //         break;
                //     default:
                //         this.toast.error('Invalid form mode.');
                // }
            } else {
                this.onSubmit.emit({
                    data: charityCrudFormSchema.parse(data),
                    image: this.image,
                    heroImage: this.heroImage,
                });
            }
        } catch (error) {
            const message = extractErrorMessageFromError(error);
            this.toast.error(message);
        }
    }


    /**
     * Event fired when an lp-form-image selects a new image
     * @param event
     * @param field
     */
    public onImageChange(event: IImageChangeEvent, field: string) {
        super.onImageChange(event, field);
    }

    get slugPlaceholder() {
        const slug = this.form.controls.displayName.value || 'my-charity';

        return slug
            .trim()
            .toLowerCase()
            .replace(/ /g, '-') // Replace all spaces with hyphen
            .replace(/[^a-zA-Z0-9-_]/g, ''); // Remove all other illegal characters
    }
}

const falseyToNull = (value: unknown) => {
    return !value
        ? null
        : value;
};

export const charityCrudFormSchema = z.object({
    displayName: z
        .string({
            required_error: 'Display name is required.',
            invalid_type_error: 'Display name is an invalid type.',
        })
        .min(6, 'Display name must contain at least 8 characters.')
        .max(64, 'Display name must contain at most 64 characters.'),

    category: z.string({
        required_error: 'Category is required.',
        invalid_type_error: 'Category is an invalid type.',
    }),

    displayLocation: z.string({
        required_error: 'Location is required.',
        invalid_type_error: 'Location is an invalid type.',
    }),

    latitude: z.number({
        required_error: 'Latitude is required.',
        invalid_type_error: 'Latitude is an invalid type.',
    }),

    longitude: z.number({
        required_error: 'Longitude is required.',
        invalid_type_error: 'Longitude is an invalid type.',
    }),

    description: z.string({
        required_error: 'Description is required.',
        invalid_type_error: 'Description is an invalid type.',
    }),

    slug: z.preprocess(
        falseyToNull,
        z
            .string({
                required_error: 'Custom URL is required.',
                invalid_type_error: 'Custom URL is an invalid type.',
            })
            .nullish(),
    ),

    youtubeUrl: z.preprocess(
        falseyToNull,
        z
            .string({
                required_error: 'YouTube URL is required.',
                invalid_type_error: 'YouTube URL is an invalid type.',
            })
            .nullish(),
    ),

});

export const charityCreateAdminFormSchema = charityCrudFormSchema.extend({
    isDGRRegistered: z
        .boolean({
            required_error: 'DGR Registered is required.',
            invalid_type_error: 'DGR Registered is an invalid type.',
        })
        .nullish(),

    isDGRRegisteredOverridden: z
        .boolean({
            required_error: 'DGR Registered Overridden is required.',
            invalid_type_error: 'DGR Registered Overridden is an invalid type.',
        })
        .nullish(),
});

export const charityEditAdminFormSchema = charityCrudFormSchema.extend({
    automaticPayoutsDisabled: z.boolean({
        required_error: 'Automatic payout disabled is required.',
        invalid_type_error: 'Automatic payout disabled is an invalid type.',
    }),

    isDGRRegistered: z
        .boolean({
            required_error: 'DGR Registered is required.',
            invalid_type_error: 'DGR Registered is an invalid type.',
        }),

    isDGRRegisteredOverridden: z
        .boolean({
            required_error: 'DGR Registered Overridden is required.',
            invalid_type_error: 'DGR Registered Overridden is an invalid type.',
        }),
});

export type CharityCrudFormData = z.infer<typeof charityCrudFormSchema>;
export type CharityCreateAdminFormData = z.infer<typeof charityCreateAdminFormSchema>;
export type CharityEditAdminFormData = z.infer<typeof charityEditAdminFormSchema>;
