import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { User } from '../../_models/user.model';
import { AuthService } from '../../_services/auth.service';

@Component({
    selector: 'app-user-avatar',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './user-avatar.component.html',
    styles: [
        `
            :host {
                display: block;
            }
        `,
    ],
})
export class UserAvatarComponent {

    @Input() includeName = false;
    @Input() size = 50;
    @Input() fontSize: number;

    public user: User;

    constructor(private authService: AuthService) {
        this.authService.userObservable.subscribe(({ newValue }) => {
            this.user = newValue;
        });
    }

}
