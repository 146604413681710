<div class="d-flex align-items-center gap-2">
    <div
        class="rounded-full overflow-hidden"
        [style.min-height.px]="size"
        [style.max-height.px]="size"
        [style.min-width.px]="size"
        [style.max-width.px]="size"
    >
        <img
            *ngIf="user.imageUrl"
            class="h-full w-full bg-reliable-blue-5"
            [src]="user.imageUrl"
            alt="">

        <div
            *ngIf="user?.firstName && user?.lastName && !user?.imageUrl"
            class="flex items-center justify-center h-full w-full bg-secondary text-white">
            <p
                [style.font-size.px]="fontSize"
                [style.line-height.px]="size"
            >
                {{user.firstName.charAt(0) + user.lastName.charAt(0)}}
            </p>
        </div>
    </div>

    <p *ngIf="includeName && user?.firstName && user?.lastName">
        {{user.firstName}} {{user.lastName}}
    </p>
</div>
