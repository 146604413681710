/*
 * app.routes.ts
 * Little Phil
 *
 * Created on 18/9/18
 * Copyright © 2018 Little Phil. All rights reserved.
 */
import { Routes } from '@angular/router';
import { ROUTE_PATHS } from '@little-phil/js/lib/common/constants';

import { AuthGuard } from './_guards/auth.guard';
import { GuestGuard } from './_guards/guest.guard';
import { AdminGuard } from './_guards/admin.guard';
import { charityDashboardAuthGuard } from './_guards/charity-dashboard-auth-guard';
import { userRoleGuard } from './_guards/user-role.guard';
import { USER_ROLE } from '@little-phil/js/lib/common/enums';
import { authGuard } from './_guards/auth-v2.guard';
import { CharityGuard } from './_guards/charity.guard';


const fallbackRoute = {
    path: '**',
    redirectTo: ROUTE_PATHS.web.explore, // don't use .substring(1) here as the leading "/" is needed for loadChildren routes
};

/**
 * Backward compatible for legacy links (iOS app, socials, share links, etc)
 */
const legacyRoutes: Routes = [
    {
        path: 'campaign/details/:id',
        redirectTo: 'campaign/:id',
        pathMatch: 'full',
    },
    {
        path: 'register',
        redirectTo: 'login',
        pathMatch: 'full',
    },
    {
        path: 'forgot',
        redirectTo: 'account-recovery',
        pathMatch: 'full',
    },
];

export class AppRouter {

    /**
     * Root routes for entire application.
     *
     * To lazy loaded Angular modules, use `loadChildren` instead of `component` in the route.
     * For more information check out:
     * https://angular.io/guide/lazy-loading-ngmodules#lazy-loading-basics
     */
    public static routes: Routes = [
        ...legacyRoutes,
        {
            path: ROUTE_PATHS.web.shareToSocialPlatform.substring(1),
            loadChildren: () => import('./share-to-social-platform/share-to-social-platform.module')
                .then(m => m.ShareToSocialPlatformModule),
        },
        {
            path: ROUTE_PATHS.web.apply.substring(1),
            loadChildren: () => import('./apply/apply.module').then(m => m.ApplyModule),
        },
        {
            path: ROUTE_PATHS.web.campaign.details(':id').substring(1),
            loadChildren: () => import('./campaign/campaign.module').then(m => m.CampaignModule),
        },
        {
            path: ROUTE_PATHS.web.charity.onboarding.substring(1),
            loadComponent: () => import('./charity/onboarding/page.component').then(mod => mod.PageComponent),
            canActivate: [
                AuthGuard,
                userRoleGuard({
                    acceptedRoles: [USER_ROLE.ADMIN, USER_ROLE.USER],
                }),
            ],
            data: {
                hideNavBar: true,
                hideFooter: true,
            },
        },
        {
            path: ROUTE_PATHS.web.charity.details(':id').substring(1),
            loadChildren: () => import('./charity/charity.module').then(m => m.CharityModule),
        },
        {
            path: ROUTE_PATHS.web.explore.substring(1),
            loadChildren: () => import('./explore/explore.module').then(m => m.ExploreModule),
        },
        {
            path: ROUTE_PATHS.web.accountRecovery.substring(1),
            loadChildren: () => import('./account-recovery/account-recovery.module').then(m => m.AccountRecoveryModule),
        },
        {
            path: ROUTE_PATHS.web.give.substring(1),
            loadChildren: () => import('./giving-gateway/giving-gateway.module').then(m => m.GivingGatewayModule),
        },
        {
            path: ROUTE_PATHS.web.resetPassword.substring(1),
            loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule),
        },
        {
            path: ROUTE_PATHS.web.setPassword.substring(1),
            loadChildren: () => import('./set-password/set-password.module').then(m => m.SetPasswordModule),
        },
        {
            path: ROUTE_PATHS.web.claimWelcome.substring(1),
            loadChildren: () => import('./claim-welcome-credit/claim-welcome-credit.module').then(m => m.ClaimWelcomeCreditModule),
        },
        {
            path: ROUTE_PATHS.web.login.substring(1),
            loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
            canActivate: [GuestGuard],
        },
        {
            path: ROUTE_PATHS.web.linkAccount.substring(1),
            loadChildren: () => import('./link-social-account/link-social-account.module').then(m => m.LinkSocialAccountModule),
        },
        {
            path: ROUTE_PATHS.web.crypto.substring(1),
            loadChildren: () => import('./crypto/crypto.module').then(m => m.CryptoModule),
        },
        {
            path: ROUTE_PATHS.web.impact.substring(1),
            loadChildren: () => import('./my-impact/my-impact.module').then(m => m.MyImpactModule),
            canActivate: [AuthGuard],
            runGuardsAndResolvers: 'always',
        },
        {
            path: ROUTE_PATHS.web.account.substring(1),
            loadChildren: () => import('./my-account/my-account.module').then(m => m.MyAccountModule),
            canActivate: [AuthGuard],
            runGuardsAndResolvers: 'always',
        },
        {
            path: ROUTE_PATHS.web.redeemCredit.substring(1),
            loadChildren: () => import('./redeem-credit/redeem-credit.module').then(m => m.RedeemCreditModule),
            canActivate: [AuthGuard],
            runGuardsAndResolvers: 'always',
        },
        {
            path: ROUTE_PATHS.web.campaign.create.substring(1),
            loadComponent: () => import('./dashboard/campaign/create/dashboard-campaign-create-page/dashboard-campaign-create-page.component')
                .then(mod => mod.DashboardCampaignCreatePageComponent),
            canActivate: [
                CharityGuard,
                charityDashboardAuthGuard(),
            ],
            runGuardsAndResolvers: 'always',
            data: { preload: false },
        },
        {
            path: ROUTE_PATHS.web.campaign.edit(':id').substring(1),
            loadComponent: () => import('./dashboard/campaign/edit/dashboard-campaign-edit-page/dashboard-campaign-edit-page.component')
                .then(mod => mod.DashboardCampaignEditPageComponent),
            canActivate: [
                CharityGuard,
                charityDashboardAuthGuard(),
            ],
            runGuardsAndResolvers: 'always',
            data: { preload: false },
        },
        {
            path: ROUTE_PATHS.web.charity.edit(':id').substring(1),
            loadComponent: () => import('./dashboard/charity/edit/dashboard-charity-edit-page/dashboard-charity-edit-page.component')
                .then(mod => mod.DashboardCharityEditPageComponent),
            canActivate: [
                CharityGuard,
                charityDashboardAuthGuard({ requireProfile: false }),
            ],
            runGuardsAndResolvers: 'always',
            data: { preload: false },
        },
        {
            path: ROUTE_PATHS.web.charity.activateProfile.substring(1),
            loadComponent: () => import('./dashboard/charity/activate-profile/dashboard-charity-activate-profile-page.component')
                .then(mod => mod.DashboardCharityActivateProfilePageComponent),
            canActivate: [
                CharityGuard,
                charityDashboardAuthGuard({ requireProfile: false }),
            ],
            runGuardsAndResolvers: 'always',
            data: { preload: false },
        },
        {
            path: ROUTE_PATHS.web.settings.substring(1),
            loadComponent: () => import('./dashboard/settings/dashboard-settings-charity/dashboard-settings-charity.component')
                .then(mod => mod.DashboardSettingsCharityComponent),
            canActivate: [
                CharityGuard,
                charityDashboardAuthGuard({ requireProfile: false }),
            ],
            runGuardsAndResolvers: 'always',
            data: { preload: false },
        },
        {
            path: ROUTE_PATHS.web.charity.admin(':id').substring(1),
            loadComponent: () => import('./dashboard/charity/admin/dashboard-charity-admin/dashboard-charity-admin.component')
                .then(mod => mod.DashboardCharityAdminComponent),
            canActivate: [AdminGuard],
            runGuardsAndResolvers: 'always',
            data: { preload: false },
        },
        {
            path: ROUTE_PATHS.web.charity.create.substring(1),
            loadComponent: () => import('./dashboard/charity/create-lead/page.component')
                .then(mod => mod.PageComponent),
            canActivate: [AdminGuard],
            runGuardsAndResolvers: 'always',
            data: { preload: false },
        },
        {
            path: ROUTE_PATHS.web.dashboardProfile.route.substring(1),
            loadComponent: () => import('./dashboard/profile/profile.component')
                .then(m => m.ProfileComponent),
            canActivate: [
                AuthGuard,
                userRoleGuard({
                    acceptedRoles: [
                        USER_ROLE.ADMIN,
                        USER_ROLE.CHARITY,
                        USER_ROLE.COMPANY_MANAGER,
                    ],
                }),
            ],
            runGuardsAndResolvers: 'always',
            data: { preload: false },
        },
        {
            path: ROUTE_PATHS.web.dashboard.employees.route.substring(1),
            loadComponent: () => import('./dashboard/employees/employees.component').then(m => m.EmployeesComponent),
            canActivate: [
                AuthGuard,
                userRoleGuard({
                    acceptedRoles: [USER_ROLE.ADMIN, USER_ROLE.COMPANY_MANAGER],
                }),
            ],
            runGuardsAndResolvers: 'always',
            data: { preload: false },
        },
        {
            path: ROUTE_PATHS.web.dashboard.givingCredits.route.substring(1),
            loadComponent: () => import('./dashboard/giving-credits/giving-credits.component').then(m => m.GivingCreditsComponent),
            canActivate: [
                AuthGuard,
                userRoleGuard({
                    acceptedRoles: [USER_ROLE.ADMIN, USER_ROLE.COMPANY_MANAGER],
                }),
            ],
            runGuardsAndResolvers: 'always',
            data: { preload: false },
        },
        {
            path: ROUTE_PATHS.web.dashboard.route.substring(1),
            loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
            canActivate: [
                AuthGuard,
                userRoleGuard({
                    acceptedRoles: [
                        USER_ROLE.ADMIN,
                        USER_ROLE.CHARITY,
                        USER_ROLE.COMPANY_MANAGER,
                    ],
                }),
            ],
            runGuardsAndResolvers: 'always',
            data: { preload: false },
        },
        {
            path: ROUTE_PATHS.web.companies.substring(1),
            loadChildren: () => import('./company-list/company-list.module').then(m => m.CompanyListModule),
        },
        {
            path: ROUTE_PATHS.web.company.onboarding.substring(1),
            loadComponent: () => import('./company/onboarding/onboarding.component')
                .then(m => m.OnboardingComponent),
            canActivate: [
                authGuard({
                    role: USER_ROLE.COMPANY_MANAGER,
                }),
                userRoleGuard({
                    acceptedRoles: [USER_ROLE.ADMIN, USER_ROLE.USER],
                }),
            ],
            data: {
                hideNavBar: true,
                hideFooter: true,
            },
        },
        {
            path: ROUTE_PATHS.web.notFound.substring(1),
            loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule),
        },
        {
            path: ROUTE_PATHS.web.error.substring(1),
            loadChildren: () => import('./error/error.module').then(m => m.ErrorModule),
        },
        {
            path: ROUTE_PATHS.web.loading.substring(1),
            loadChildren: () => import('./loading/loading.module').then(m => m.LoadingModule),
        },
        {
            path: ROUTE_PATHS.web.customerPortal.substring(1),
            loadChildren: () => import('./customer-portal/customer-portal.module').then(m => m.CustomerPortalModule),
        },
        {
            path: 'dashboard/billing',
            loadComponent: () => import('./dashboard/billing/dashboard-billing-page.component')
                .then(mod => mod.DashboardBillingPageComponent),
        },
        {
            path: ROUTE_PATHS.web.dashboardCharityAssistant.edit.substring(1),
            loadComponent: () => import('./dashboard/charity-assistant/edit/edit.component').then(m => m.EditComponent),
            canActivate: [AdminGuard],
        },
        {
            path: ROUTE_PATHS.web.dashboardPayments.substring(1),
            loadComponent: () => import('./dashboard/payments/payments.component').then(m => m.PaymentsComponent),
            canActivate: [
                authGuard(),
                userRoleGuard({
                    acceptedRoles: [USER_ROLE.ADMIN, USER_ROLE.CHARITY, USER_ROLE.COMPANY_MANAGER],
                }),
                charityDashboardAuthGuard({ requireProfile: false }),
            ],
        },
        {
            path: ROUTE_PATHS.web.dashboard.payments.view.route(':id').substring(1),
            loadComponent: () => import('./dashboard/payments/id/payment-view.component').then(m => m.PaymentViewComponent),
            canActivate: [
                authGuard(),
                userRoleGuard({
                    acceptedRoles: [USER_ROLE.ADMIN, USER_ROLE.CHARITY, USER_ROLE.COMPANY_MANAGER],
                }),
            ],
        },
        {
            path: ROUTE_PATHS.web.dashboard.company.actAs(':id').substring(1),
            loadComponent: () => import('./dashboard/company/id/act-as/act-as.component').then(m => m.ActAsComponent),
            canActivate: [AdminGuard],
        },
        {
            path: ROUTE_PATHS.web.dashboard.charity.actAs(':id').substring(1),
            loadComponent: () => import('./dashboard/charity/id/act-as/act-as.component').then(m => m.ActAsComponent),
            canActivate: [AdminGuard],
        },
        fallbackRoute,
    ];

}
